.card {
  display: flex;
  width: 100%;
  flex-basis: 47%;
  padding: 2rem;
  height: 10rem;
  border-radius: 10px;
  background: white;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  text-decoration: none;
  color: black;
  justify-content: space-between;
  align-items: center;
  transition: 2s ease-in-out;
}
.card:hover {
  transform: scale(1.1);
}
.card p {
  font-size: 14px;
  margin-bottom: 1rem;
}
.text {
  display: flex;
  flex: 0.7;
  flex-direction: column;
}
.text h1 {
  font-size: 2rem;
}
.circle {
  display: flex;
  width: 80px;
  height: 80px;
  background: rgba(100, 10, 210, 0.3);
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: white;
  margin-top: 1rem;
}
.icircle {
  display: flex;
  width: 65px;
  height: 65px;
  background: #640ad2;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.icircle svg {
  font-size: 2rem;
}

.purple {
  color: #f2ebfb;
  background: #713bdb;
}
.lightPurple {
  background: #f2ebfb;
}

.cyan {
  background: #49b8ff;
  color: #eef9ff;
}
.lightCyan {
  background: #eef9ff;
}
.gold {
  color: #fff4ee;
  background: #ffca6f;
}

.lightGold {
  background: #fff4ee;
}

.crimson {
  color: #ffeff2;
  background: #fd5181;
}
.lightCrimson {
  background: #ffeff2;
}

.green {
  color: #d7ffe1;
  background: rgba(8, 126, 140, 1);
}
.lightGreen {
  background: rgba(10, 146, 161, 0.2);
}

/* Media Query */

@media screen and (min-width: 1600px) and (max-width: 1800px) {
  .card {
    flex-basis: 47%;
  }
}
@media screen and (min-width: 1300px) and (max-width: 1600px) {
  .card {
    flex-basis: 30%;
  }
}

@media screen and (max-width: 1300px) {
  .card {
    flex-basis: 30%;
  }
}

@media screen and (min-width: 1050px) and (max-width: 1300px) {
  .card {
    flex-basis: 47%;
  }
}

@media screen and (min-width: 700px) and (max-width: 1050px) {
  .card {
    flex-basis: 46%;
  }
}
@media screen and (max-width: 700px) {
  .card {
    flex-basis: 46%;
  }
}

@media screen and (max-width: 550px) {
  .card {
    flex-basis: 100%;
  }
}
