.cardContainer {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 2rem;
  flex-basis: 50%;
}
.titleContainer {
  display: flex;
  padding: 0.5rem 0;
  align-items: center;
  font-size: 13px;
}
.inputContainer {
  display: flex;
  width: 100%;
  min-height: 3.5rem;
  outline: none;
  padding: 0.5rem 1.5rem;
  border-radius: 10px;
  border: 1px solid gainsboro;
  background: whitesmoke;
  align-items: center;
  font-size: 13px;
}
