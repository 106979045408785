.iCodeCustomInput {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 2rem;
  flex-basis: 50%;
}

.ilarge {
  flex-basis: 100%;
}

.iMedium {
  flex-basis: 50%;
}

.iCodeCustomInput label {
  font-size: 13px;
  z-index: 1;
  margin-bottom: 0.5rem;
}

.iCodeCustomInput input {
  width: 100%;
  height: 3.5rem;
  outline: none;
  padding: 0.5rem 1.5rem;
  border-radius: 10px;
  border: 1px solid gainsboro;
  background: none;
}

.iCodeCustomInput input:read-only {
  background: whitesmoke;
}

.btnTop {
  padding: 2rem;
}

.approvalContainer {
  width: 100%;
  display: flex;
  padding: 1rem 2rem;
  /* justify-content: space-between; */
}

.approvalListContainer {
  display: flex;
  flex-direction: column;
  flex: 0.5;
  padding: 1rem;
  border: 2px dotted gainsboro;
  /* flex-wrap: wrap;
  gap: 1rem; */
  margin-right: 2rem;
}

.approvalBoardContainer {
  display: flex;
  flex-direction: column;
  flex: 0.5;
  padding: 1rem;
  border: 2px dotted gainsboro;
  background: whitesmoke;
  /* flex-wrap: wrap;
  gap: 1rem; */
}

.addBg {
  background: rgba(8, 126, 140, 1);
}

.noBg {
  background: whitesmoke;
}

.s404Screen {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo {
  display: flex;
  max-height: 5rem;
  padding: 0.5rem;
  /* justify-content: center; */
  align-items: center;
  margin-bottom: 0.5rem;
}

.logo img {
  width: 100%;
  object-fit: contain;
  max-height: 4rem;
}