.loader {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background: rgba(255, 255, 255, 0.7);
  color: #4fa94d;
  padding: 2rem;
  justify-content: center;
  align-items: center;
}

.loader p {
  font-size: 12px;
}
