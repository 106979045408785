.listContainer {
  display: flex;
  padding: 0.5rem 2rem;
  width: 100%;
  flex-basis: 46%;
}
.list {
  width: 100%;
  display: flex;
  padding: 0.5rem;
  border-radius: 5px;
  justify-content: space-between;
  background: rgba(250, 250, 250, 0.8);
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  font-size: 13px;
  align-items: center;
}
