.home {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  background: linear-gradient(
      270deg,
      rgba(8, 126, 140, 0.7),
      rgba(165, 208, 83, 0.5)
    ),
    url("../../assets/borrower.jpg");
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  animation: slideBg 20s ease-in-out infinite 8s;
  animation-timing-function: ease-in-out;
  background-repeat: no-repeat;
  flex-direction: column;
  justify-content: center;
  padding: 5rem;
  color: white;
  align-items: center;
}

.home h1 {
  font-size: 3rem;
}

@keyframes slideBg {
  0% {
    background: linear-gradient(
        270deg,
        rgba(8, 126, 140, 0.7),
        rgba(165, 208, 83, 0.5)
      ),
      url("../../assets/man.jpg");
    background-size: cover;
  }

  25% {
    background: linear-gradient(
        270deg,
        rgba(8, 126, 140, 0.7),
        rgba(165, 208, 83, 0.5)
      ),
      url("../../assets/trader.jpg");
    background-size: cover;
  }

  50% {
    background: linear-gradient(
        270deg,
        rgba(8, 126, 140, 0.7),
        rgba(165, 208, 83, 0.5)
      ),
      url("../../assets/slider-bg.jpg");
    background-size: cover;
  }

  75% {
    background: linear-gradient(
        270deg,
        rgba(8, 126, 140, 0.7),
        rgba(165, 208, 83, 0.5)
      ),
      url("../../assets/borrower.jpg");
    background-size: cover;
  }

  100% {
    background: linear-gradient(
        270deg,
        rgba(8, 126, 140, 0.7),
        rgba(165, 208, 83, 0.5)
      ),
      url("../../assets/slider-bg.jpg");
    background-size: cover;
  }
}

.logo {
  width: 100%;
  display: flex;
  height: 5rem;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-bottom: 2rem;
}
.logo img {
  object-fit: contain;
  max-height: 5rem;
}
.loginBtn {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.loginBtn p {
  margin-bottom: 1rem;
}
