.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  transition: all 0.3s;
}

.switch:after {
  content: "";
  position: absolute;
  width: 38px;
  height: 28px;
  border-radius: 18px;
  background-color: white;
  top: 1px;
  left: 1px;
  transition: all 0.3s;
}

input[type="checkbox"]:checked + .switch:after {
  transform: translateX(20px);
}

input[type="checkbox"]:checked + .switch {
  background-color: rgba(8, 126, 140, 1);
}

.offscreen {
  position: absolute;
  left: -9999px;
}
.InputContainer {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 2rem;
}
.InputContainer label {
  font-size: 13px;
  z-index: 1;
  margin-bottom: 0.5rem;
}
