.customTable {
  width: 100%;
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
}

.customTable table {
  width: 100%;
  max-width: 100%;
  margin: 10px auto;
}

.customTable thead th {
  font-weight: 400;
  background: rgba(8, 126, 140, 1);
  color: #fff;
}

.customTable tr {
  background: #f4f7f8;
  border-bottom: 1px solid #fff;
  margin-bottom: 5px;
}

.customTable tr:nth-child(even) {
  background: #e8eeef;
}

.customTable th,
td {
  text-align: left;
  padding: 20px;
  font-weight: 300;
}

.largex {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
}

.largexCo {
  display: flex;
  flex-wrap: wrap;
}