.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(18, 38, 74, 0.48);
  animation-name: appear;
  animation-duration: 300ms;
  flex-direction: column;
}

.modal-dialog {
  width: 100%;
  background: white;
  position: relative;
  margin: 0 20px;
  max-height: calc(100vh - 40px);
  text-align: left;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: slide-in;
  animation-duration: 0.5s;
  border-radius: 10px;
}

.md {
  max-width: 550px;
}

.lg {
  max-width: 750px;
}

.xl {
  max-width: 1250px;
}

.modal-header,
.modal-footer {
  display: flex;
  align-items: center;
  padding: 1rem;
}

.modal-header {
  /* border-bottom: 1px solid #dbdbdb; */
  justify-content: space-between;
}

.modal-footer {
  /* border-top: 1px solid #dbdbdb; */
  justify-content: flex-end;
}
.closeCon {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.modal-close {
  cursor: pointer;
  /* margin: -1rem -1rem -1rem auto; */
  font-size: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: white;
  margin-bottom: 0.5rem;
}
.modal-title {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.modal-body {
  overflow: auto;
}

.modal-body::-webkit-scrollbar {
  width: 10px;
}
.modal-body::-webkit-scrollbar-track {
  background-color: rgba(8, 126, 140, 1);
}
.modal-body::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(255, 255, 255, 1);
}

.modal-content {
  padding: 1rem;
}

@keyframes appear {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes slide-in {
  from {
    transform: translateY(-150px);
  }

  to {
    transform: translateY(0);
  }
}
