.comment {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 2rem;
  margin-bottom: 0.5rem;
  flex-basis: 50%;
}
.comment h5 {
  font-size: 16px;
}
.comment p {
  font-size: 13px;
}
.inputContainer {
  display: flex;
  width: 100%;
  /* height: 3.5rem; */
  outline: none;
  padding: 1rem 1.5rem;
  border-radius: 10px;
  border: 1px solid gainsboro;
  background: whitesmoke;
  align-items: center;
  font-size: 13px;
}
