.aCard {
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid gray;
  padding: 0.5rem;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    url("../../assets/gradient.jpeg");
  background-size: cover;
  margin-bottom: 1rem;
  cursor: pointer;
  flex-basis: 24%;
}

.shadow {
  border: 2px solid rgba(8, 126, 140, 1);
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
.noShadow {
  box-shadow: none;
}

.team {
  display: flex;
  width: 100%;
  padding: 0.5rem;
  font-size: 12px;
  font-weight: bold;
  border-bottom: 1px solid gainsboro;
}
.inputs {
  display: flex;
  flex-direction: column;
  font-size: 11px;
  padding: 0.5rem;
}
.inputs p {
  margin-bottom: 0.5rem;
}

.inputs select {
  height: 2rem;
}
.controls {
  display: flex;
  justify-content: flex-end;
}
.controls svg {
  margin-right: 0.5rem;
  color: rgba(8, 126, 140, 1);
}
