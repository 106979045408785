.header {
  display: flex;
  border-bottom: 1px solid rgba(200, 200, 200, 0.5);
  /* background: ; */
  align-items: center;
  justify-content: space-between;
  color: white;
  padding: 0.5rem 1rem;
}
.pageTitle {
  display: flex;
  flex: 0.3;
  color: black;
  align-items: center;
}
.pageTitle svg {
  margin-right: 1rem;
  font-size: 20px;
}
.search {
  display: flex;
  flex: 0.3;
  border-radius: 200px;
  background: whitesmoke;
  padding: 0.5rem 1rem;
  align-items: center;
  color: black;
  overflow: hidden;
}

.searchContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.searchContainer input {
  width: 100%;
  outline: none;
  border: none;
  /* margin-right: 1rem; */
  height: 2rem;
  background: none;
  color: black;
}

.search input::placeholder {
  color: black;
}

.noautocomplete {
  color: #999;
  padding: 8px;
}

.autocomplete {
  display: flex;
  flex-direction: column;
  border: 1px solid #999;
  border-top-width: 0;
  list-style: none;
  margin-top: 3rem;
  /* max-height: 143px; */
  overflow-y: auto;
  padding-left: 0;
  width: 50%;
  position: absolute;
  background: rgb(36, 36, 36);
  color: white;
  z-index: 9999;
}

.autocomplete li {
  padding: 8px;
}

.autocomplete > .active,
.autocomplete li:hover {
  background-color: whitesmoke;
  cursor: pointer;
  font-weight: 600;
}

.autocomplete li:not(:last-of-type) {
  border-bottom: 1px solid #999;
}

/* Media Query */

@media screen and (min-width: 1300px) and (max-width: 1600px) {
}

@media screen and (max-width: 1300px) {
}

@media screen and (min-width: 1050px) and (max-width: 1300px) {
}

@media screen and (max-width: 1050px) {
}

@media screen and (max-width: 1024px) {
}

@media screen and (min-width: 700px) and (max-width: 1050px) {
}

@media screen and (max-width: 700px) {
}

@media screen and (max-width: 550px) {
  .header {
    flex-direction: column;
    padding: 0.3rem;
    justify-content: flex-start;
  }

  .search {
    width: 100%;
    flex: 1;
  }
  .profile {
    /* display: none; */
  }
}
